import { Alert, Box, FormHelperText, Typography } from "@mui/material";
import CabinetPage from "../../components/Common/CabinetPage";
import { 
  Company, CompanyCategory 
} from "../../store/cabinetApi/generated/crm";
import colors from "../../colors";
import CabAvatar from "@CabComponents/CabAvatar";
import { CabButton } from "@CabComponents/CabButton";
import { ContactOrgDenorm } from "../../store";
import CabSpinner from "@CabComponents/CabSpinner";
import { BasicInfoListItem, Widget, WidgetList } from "../../components/CRM/SharedCrmComponents";
import CRMContactsTable from "../../components/CRM/CRMContacts/CRMContacts";
import useContactsList from "../../components/CRM/CRMContacts/contactsListHook";
import { useState } from "react";
import { CabModal } from "@CabComponents/CabModal";
import Dropzone from "react-dropzone";
import { Controller, useForm } from "react-hook-form";
import BackButton from "@CabComponents/CabBackButton";
import { ALLOWED_IMG_EXTS } from "../../constants";


const CRMCompanyView = ({
  organization, contactsListData, onNavigateToCompanies, categoryOptions, onSaveBasicInfo, loading,
}: {
  organization: ContactOrgDenorm | null;
  contactsListData: ReturnType<typeof useContactsList>;
  onNavigateToCompanies: () => void;
  categoryOptions: CompanyCategory[];
  onSaveBasicInfo: (info: Partial<Company>) => void;
  loading: boolean;
}) => {
  const [openEditLogoModal, setOpenEditLogoModal] = useState(false);
  const [logo, setLogo] = useState<{ file: File, fileName: string } | null>();
  const [logoUrl, setLogoUrl] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>();

  const handleCancelEditLogo = () => {
    setLogoUrl(null);
    setLogo(null);
    setOpenEditLogoModal(false);
  };

  const handleSubmitLogo = async () => {
    if (logo?.file) {
      // @ts-expect-error typing wrong
      onSaveBasicInfo({ logo: logo.file });
    }
    setOpenEditLogoModal(false);
  };

  const onDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file.size > 2500000) {
      setErrorMessage("Max file size is 2.5Mb");
    } else if (!["image/png", "image/jpeg"].includes(file.type)) {
      setErrorMessage("Supported file types include png & jpeg");
    } else {
      setErrorMessage(null);
      setLogoUrl(URL.createObjectURL(file));
      setLogo({ file, fileName: file.name });
    }
  };

  return (
    <CabinetPage
      pageName="Company"
      headerContent={
        <Box width='100%' display='flex' justifyContent='space-between'>
          <BackButton />
        </Box>
      }
    >
      {organization ? (
        <Box display="flex" height="100%" width="100%" gap={3} padding={3} flexDirection="column">
          <Box display="flex" flex={1} gap={3} flexDirection={{ xs: 'column', md: 'row' }}>
            <Box display="flex" flex={2}>
              <Widget>
                <Box display="flex" gap={1} padding={2} paddingLeft={1}>
                  <Box sx={{ cursor: 'pointer' }}>
                    <CabAvatar
                      name={organization?.name || ''}
                      color={colors.black500}
                      src={organization.logo}
                      onClick={() => setOpenEditLogoModal(true)}
                    />
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="h1">{organization?.name}</Typography>
                    <Typography>{organization?.primary_contact?.name || ''}</Typography>
                  </Box>
                </Box>
              </Widget>
            </Box>
            <Box display="flex" flex={3}>
            </Box>
          </Box>
          <Box display="flex" flex={12} gap={3} flexDirection={{ xs: 'column', md: 'row' }}>
            <Box display="flex" flex={2}>
              <Widget>
                <BasicInfoView
                  organization={organization}
                  onSave={onSaveBasicInfo}
                  categoryOptions={categoryOptions}
                />
              </Widget>
            </Box>
            <Box display="flex" flex={3} flexDirection="column" gap={3}>
              <Box display="flex" flex={3}>
                <Widget sx={{ padding: 0 }}>
                  <Box position="relative" height="100%">
                    <CRMContactsTable
                      {...contactsListData}
                      sx={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
                      defaultHiddenFields={['company', 'contact_category', 'created_at']}
                      hideTableButtons
                      interactive={false}
                      paginate={true}
                    />
                  </Box>
                </Widget>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box hidden={loading} display='flex' justifyContent='center' marginTop={5}>
          <Alert severity="warning">
            Organization not found
          </Alert>
        </Box>
      )}
      <Box hidden={!loading} height='100%' display='flex' alignItems='center'>
        <CabSpinner scale={4} color='inherit' />
      </Box>
      <CabModal 
        open={openEditLogoModal}
        onClose={() => setOpenEditLogoModal(false)}
        title={'Update Company Logo'}
        closeIcon
        closeOnBackdropClick
        actionButtons={
          <>
            <CabButton buttonType='secondary' onClick={handleCancelEditLogo}>Cancel</CabButton>
            <CabButton onClick={handleSubmitLogo}>Save</CabButton>
          </>
        }
      >
        <Dropzone onDrop={onDrop} accept={{"": ALLOWED_IMG_EXTS}} useFsAccessApi={false}>
          {({ getRootProps, getInputProps }) => (
            <Box display="flex" flexDirection="row" gap={2} padding={2} marginBottom={1}
              border={`1px solid ${colors.black200}`} borderRadius={1}
              {...getRootProps()} sx={{ cursor: 'pointer' }}
            >
              <CabAvatar
                name={organization?.name || ''}
                src={logoUrl || organization?.logo}
                size="large"
                sx={{height: '80px', width: '80px'}}
              />
              <Box>
                <input {...getInputProps()} />
                <Typography>
                  Drag and drop file or <Box component="span" sx={{ textDecoration: 'underline' }}>
                    click here
                  </Box>
                </Typography>
                <Typography variant="caption" color={colors.black800} fontSize={12}>
                  JPG, GIF or PNG. Max size of 2.5Mb
                </Typography>
                {errorMessage &&
                  <FormHelperText error>{errorMessage}</FormHelperText>
                }
              </Box>
            </Box>
          )}
        </Dropzone>
      </CabModal>

    </CabinetPage>
  );
};

interface BasicInfoForm {
  name: string;
  domain: string;
  category: number | null;
  notes: string;
}

const BasicInfoView = ({ organization, categoryOptions, onSave }: {
  organization: ContactOrgDenorm, 
  onSave: (info: Partial<Company>) => void;
  categoryOptions: CompanyCategory[];
}) => {
  const { control, handleSubmit, reset, formState } = useForm<BasicInfoForm>({
    values: {
      name: organization.name || '',
      domain: organization.email_domain || '',
      category: organization.category?.id || null,
      notes: organization.notes || '',
    }
  });

  const handleSubmitForm = (values: BasicInfoForm) => {
    onSave(values);
  };

  return (
    <WidgetList>
      {formState.isDirty && (
        <Box display="flex" width="100%" marginTop={2} gap={2}>
          <CabButton
            size="small"
            onClick={() => reset()}
            buttonType="tertiary"
            sx={{ flex: 1}}
          >
            Discard Changes
          </CabButton>
          <CabButton
            size="small"
            onClick={handleSubmit(handleSubmitForm)}
            sx={{ flex: 1}}
          >
            Save Fields
          </CabButton>
        </Box>
      )}

      <Controller name="name" control={control} render={({ field: { ref, ...field }, fieldState }) => (
        <BasicInfoListItem inputType="text" title="Name"
          inputValue={field.value}
          text={field.value}
          onSave={field.onChange}
          isDirty={fieldState.isDirty}
        />
      )} />
      <Controller name="domain" control={control} render={({ field: { ref, ...field }, fieldState }) => (
        <BasicInfoListItem inputType="text" title="Domain"
          inputValue={field.value}
          text={field.value}
          onSave={field.onChange}
          isDirty={fieldState.isDirty}
        />
      )} />
      <Controller name="category" control={control} render={({ field: { ref, ...field }, fieldState }) => (
        <BasicInfoListItem inputType="select" title="Category"
          inputValue={field.value}
          selectOptions={categoryOptions.map(o => ({ value: o.id, label: o.name, color: o.color }))}
          color={categoryOptions.find(o => o.id === field.value)?.color}
          text={categoryOptions.find(o => o.id === field.value)?.name || 'Unassigned'}
          onSave={field.onChange}
          isDirty={fieldState.isDirty}
        />
      )} />
      <Controller name="notes" control={control} render={({ field: { ref, ...field }, fieldState }) => (
        <BasicInfoListItem inputType="multiline" title="Notes"
          inputValue={field.value}
          text={field.value}
          onSave={field.onChange}
          isDirty={fieldState.isDirty}
        />
      )} />
    </WidgetList>
  );
};

export default CRMCompanyView;
